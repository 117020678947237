<template>
<div>
    <Navbar />
    <MobileMenuCabinet/>
    <!-- <div class="mobileMenu" v-if="$store.state.showMobMenu">
        <div class="navbar-vertical-mob" v-click-outside="onClickOutside">
            <span @click="hideMenu('/cabinet/contacts')" class="navbar-vertical-mob__btn">Контактная информация</span>
            <span @click="hideMenu('/cabinet/history')" class="navbar-vertical-mob__btn">История заказов</span>
            <span @click="hideMenu('/cabinet/bill')" class="navbar-vertical-mob__btn">Внутренний счет</span>
            <span @click="hideMenu('/cabinet/filter')" class="navbar-vertical-mob__btn">Настройка фильтров</span>
            <span @click="hideMenu('/cabinet/auto')" class="navbar-vertical-mob__btn">Автозаказ</span>
            <button @click='logout()' class="navbar-vertical-mob__btn navbar-vertical-mob__btn--white mt-3">Выйти из кабинета</button>
        </div>
    </div> -->
    <div class="container">
        <div class="cabinet">
            <div class="cabinet__nav d-none d-md-block">
                <NavbarVertical />
            </div>
            <div class="cabinet__headerMob d-md-none">
                <router-link to="/"></router-link>
                {{this.$route.meta.title}}
            </div>
            <router-view></router-view>
        </div>
    </div>
    <Footer />
</div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import NavbarVertical from '@/components/NavbarVertical.vue'
import vClickOutside from 'v-click-outside'
import MobileMenuCabinet from '@/components/MobileMenuCabinet.vue'
export default {
    name: "Cabinet",
    components: {
        Navbar,
        Footer,
        NavbarVertical,
        MobileMenuCabinet
    },
      directives: {
        clickOutside: vClickOutside.directive
    },
    methods: {
        // hideMenu(path) {
        //     this.$router.push(path)
        //     this.$store.commit('mobMenu');
        // },
        //   logout() {
        //     this.$store.dispatch("logout");
        //     this.$router.push('/')
        // },
        //     onClickOutside(event) {
             
        //     console.log(event.target._prevClass)
        //     if (event.target._prevClass != 'btn-round btn-round--bg' && event.target._prevClass != 'btn-round btn-round--grey btn-round--bgs') {
        //        this.$store.commit('mobMenu');
        //     }
        // }
    },
    mounted() {

    },
}
</script>

<style lang="scss" scoped>
.cabinet {}

.container {}

.cabinet {
    margin-top: 85px;
    padding-bottom: 270px;
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
        padding-bottom: 110px;
        margin-top: 25px;
        flex-direction: column;
    }

    &__nav {
        width: 250px;
    }

    &__content {
        width: calc(100% - 326px);
        //background: #FFFFFF !important;
        box-shadow: -10px 6px 23px rgba(156, 156, 156, 0.1);
        border-radius: 12px;
        padding: 40px 20px 0px 49px;

        @include media-breakpoint-down(md) {
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
            padding-top: 20px;
        }

        .row {
            max-width: 700px;
        }
    }

    &__header {
        margin-bottom: 29px;
        font-size: 24px;
    }
}

.contacts {
    background: url(../assets/contacts-inner.svg) 100% -5% no-repeat #fff;

    @include media-breakpoint-down(md) {
        background: #fff;

    }

    label {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        padding-left: 30px;
        margin-bottom: 5px;

        @include media-breakpoint-down(md) {
            text-align: center;
            width: 100%;
            padding-left: 0;
        }
    }

    input {
        border: none;
        border-radius: 73px;
        height: 57px;
        font-family: Montserrat;
        color: #000000;
        padding-left: 29px;
        font-weight: 600;
        background: #F5F5F5 !important;
        font-size: 16px;
        margin-bottom: 35px;

        @include media-breakpoint-down(md) {
            margin-bottom: 18px;
        }
    }

    &__btn {
        @include media-breakpoint-down(md) {
            display: flex;
            justify-content: center;
            margin-bottom: 35px;

            button {
                width: 100%;
            }
        }
    }
}

#app>div {

    background: url(../assets/contacts-outer.svg) 92% 70% no-repeat #F5F5F5;

    @include media-breakpoint-down(md) {
        background-size: contain;
    }
}

.footer {
    background: #fff;
}

.top-navbar {
    background: #fff !important;
}

// .mobileMenu {
//     position: absolute;
//     top: 124px;
//     bottom: 0;
//     //    display: none;

//     left: 0;
//     right: 0;
//     height: 100%;
//     background: #F5F5F5;
//     z-index: 9;

// }

// ////////////////////////////////////////////////////////////////////
// .navbar-vertical-mob {
//     width: calc(100% - 40px);
//     margin-left: auto;
//     margin-right: auto;

//     &__btn {
//         @extend .btn;
//         width: 100%;
//         padding-top: 22px;
//         padding-bottom: 23px;
//         margin-bottom: 10px;
//         background: #FF7728;
//         border-radius: 12px;
//         font-family: Montserrat;

//         font-weight: 500;
//         font-size: 16px;
//         line-height: 109.5%;
//         /* identical to box height, or 18px */

//         color: #fff;

//         &--white {
//             background: #fff !important;
//             color: #636363;
//             &:hover{
//                   color: #636363 !important;
//             }
//         }

//         &:hover {
//             background: $orange;
//             color: #fff;
//         }
//     }
// }

// .router-link-exact-active {
//     background: $orange;
//     color: #fff;
// }

// .navbar-vertical__btn--white-mob{
//      color: #636363;
// }
</style>
