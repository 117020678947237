<template>
<div class="navbar-vertical">
    <router-link to="/cabinet/contacts" class="navbar-vertical__btn">Контактная информация</router-link>
    <router-link to="/cabinet/history" class="navbar-vertical__btn">История заказов</router-link>
    <router-link to="/cabinet/bill" class="navbar-vertical__btn">Внутренний счет</router-link>
    <router-link to="/cabinet/filter" class="navbar-vertical__btn">Настройка фильтров</router-link>
    <router-link to="/cabinet/auto" class="navbar-vertical__btn">Автозаказ</router-link>
    <router-link to="/#menu" class="navbar-vertical__btn">Вернуться в меню</router-link>
    <button @click='logout()' class="navbar-vertical__btn navbar-vertical__btn--white mt-3">Выйти из аккаунта</button>
</div>
</template>

<style lang="scss" scoped>
.navbar-vertical {
    width: 260px;

    &__btn {
        @extend .btn;
        width: 100%;
        padding-top: 22px;
        padding-bottom: 23px;
        margin-bottom: 10px;
        background: #EBEBEB;
        border-radius: 12px;
        font-family: Montserrat;

        font-weight: 500;
        font-size: 16px;
        line-height: 109.5%;
        /* identical to box height, or 18px */

        color: #636363;

        &--white {
            background: #fff;
        }
        &:hover{
            background: $orange;
            color: #fff;
        }
    }
}
.router-link-exact-active {
    background: $orange;
    color: #fff;
}
</style>
<script>
export default {
    methods: {
        async logout(){
             this.$store.dispatch("logout");
             this.$router.push('/')
              await this.$store.dispatch('actionGetCart');
        }
    },
}
</script>
